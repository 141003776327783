const workbox = document.querySelector(".workInsideBox");
const rBtn = document.querySelector("#rightBtn");
const lBtn = document.querySelector("#leftBtn");
const picsNode = document.querySelectorAll(".frame");
const pics = [...picsNode];
let width = pics[0].offsetWidth;

console.log(width * (pics.length - 3));

let widthMargined = width * (pics.length - 3);
let i = 0;
let run = 3;
let remainderPics = pics.length - 3;

let shiftingRight = () => {
      if (remainderPics >= 3) {
            run = 3;
            remainderPics -= 3;
            console.log({ i, run, remainderPics });
            if (i >= -(pics.length - 3) * width) {
                  i -= width * run;
                  if (i >= -(pics.length - 3) * width) {
                        pics[0].style.marginLeft = `${i}px`;
                  } else return;
            }
      } else if (remainderPics == pics.length % 3) {
            remainderPics -= pics.length % 3;
            run=pics.length % 3;
            console.log({ i, run, remainderPics });
            if (i >= -(pics.length - 3) * width) {
                  i -= width * run;
                  if (i >= -(pics.length - 3) * width) {
                        pics[0].style.marginLeft = `${i}px`;
                  } else return;
            }
      } 
       else return;
};
let shiftingLeft = () => {
      if (
            remainderPics === 0 ||
            (remainderPics + 3 < pics.length - (pics.length % 3) )
      ) {
            remainderPics += 3;
            run += 3 - run;
            console.log({ i, run, remainderPics });
            if (i <= 0) {
                  i += width * run;
                  if (i <= 0) {
                        pics[0].style.marginLeft = `${i}px`;
                  } else return;
            }
      } else if (remainderPics + 3 === pics.length - (pics.length % 3)) {
            remainderPics += (pics.length % 3);
            run=pics.length % 3;
            console.log({ i, run, remainderPics });
            if (i <= 0) {
                  i += width * run;
                  if (i >= -(pics.length - 3) * width) {
                        pics[0].style.marginLeft = `${i}px`;
                  } else return;
            }
      } else return;
};

rBtn.addEventListener("click", shiftingRight);
lBtn.addEventListener("click", shiftingLeft);
