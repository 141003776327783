const container = document.querySelector("#container");
const navBar = document.querySelector(".navBar");
const location = document.querySelector(".location");
const hello = document.querySelector(".hello");
const myName = document.querySelector(".name");
const about = document.querySelector(".about");
const linked = document.querySelector(".header .linked");
const profilepic = document.querySelector(".profileImage");

const aboutme = document.querySelector(".aboutme");
const about1 = document.querySelector(".about1");
const about2 = document.querySelector(".about2");
const sig = document.querySelector(".sig");


let afterInitiator = () => {
      container.style.visibility = `hidden`;
      navBar.style.visibility = `hidden`;
      location.style.visibility = `hidden`;
      profilepic.style.visibility = `hidden`;
      hello.style.visibility = `hidden`;
      myName.style.visibility = `hidden`;
      about.style.visibility = `hidden`;
      linked.style.visibility = `hidden`;
      aboutme.style.visibility = `hidden`;
      about1.style.visibility = `hidden`;
      about2.style.visibility = `hidden`;
      sig.style.visibility = `hidden`;

      setTimeout(() => (container.style.visibility = "visible"), 2500);
      setTimeout(() => (navBar.style.visibility = "visible"), 2500);
      setTimeout(() => (location.style.visibility = "visible"), 2900);
      setTimeout(() => (profilepic.style.visibility = "visible"), 2900);
      setTimeout(() => (hello.style.visibility = "visible"), 3200);
      setTimeout(() => (myName.style.visibility = "visible"), 3300);
      setTimeout(() => (about.style.visibility = "visible"), 3500);
      setTimeout(() => (linked.style.visibility = "visible"), 3700);
      setTimeout(() => (aboutme.style.visibility = "visible"), 3800);
      setTimeout(() => (about1.style.visibility = "visible"), 3900);
      setTimeout(() => (about2.style.visibility = "visible"), 4000);
      setTimeout(() => (sig.style.visibility = "visible"), 4100);
};

window.addEventListener('load',afterInitiator)