const preloadMenu=document.querySelector('.preloaderText');
const firstName=document.querySelector('.animatedText');
const lastName=document.querySelector('.animatedText2');
const line=document.querySelector('.lineUnderText');

let initiator = ()=>{
    lastName.style.visibility=`hidden`
    line.style.visibility=`hidden`

    setTimeout(()=>lastName.style.visibility='visible',300)
    setTimeout(()=>line.style.visibility='visible',500)
    setTimeout(()=>fadeOutEffect(preloadMenu),1800)

}

const fadeOutEffect = (target) => {
    let fadeTarget = target;
    let fadeEffect = setInterval(function () {
       if (!fadeTarget.style.opacity) {
          fadeTarget.style.opacity = 1;
       }
       if (fadeTarget.style.opacity > 0) {
          fadeTarget.style.opacity -= 0.1;
       } else {
          clearInterval(fadeEffect);
       }
    }, 10);
 };

window.addEventListener('load',initiator)