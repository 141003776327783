const linked = document.querySelectorAll(".linked");
const contactModal = document.querySelector(".contactModal");
const closeBtn = document.querySelector(".closeBtn");
const container = document.querySelector("#container");
const mail = document.querySelector(".mail");
const popUp = document.querySelector(".popUp");

let clicked = () => {
      contactModal.classList.remove("heightReturner");
      contactModal.classList.add("heightChanger");
      container.style.transition = "opacity 200ms ease 300ms";
      container.style.opacity = "0";
      setTimeout(() => (container.style.visibility = "hidden"), 300);
};

let closeClicked = () => {
      contactModal.classList.add("heightReturner");
      contactModal.classList.remove("heightChanger");
      container.style.transition = "opacity 200ms ease 300ms";
      container.style.opacity = "1";
      setTimeout(() => (container.style.visibility = "visible"), 300);
};

linked.forEach((el) => {
      el.addEventListener("click", clicked);
});

closeBtn.addEventListener("click", closeClicked);

mail.onclick = function () {
      document.execCommand("copy");
};

mail.addEventListener("copy", function (event) {
      event.preventDefault();
      if (event.clipboardData) {
            event.clipboardData.setData("text/plain", mail.textContent);
            console.log(event.clipboardData.getData("text"));
      }
      popUp.style.animation = "show 200ms ease forwards";
      setTimeout(() => {
            popUp.style.animation = "none";
      }, 600);
});

let getCursorPosition=()=> {
      let x = event.clientX;
      let y = event.clientY;
      console.log(x,y)
      popUp.style.top=`${y-40}px`
      popUp.style.left=`${x}px`

    }
mail.addEventListener('click',getCursorPosition)
