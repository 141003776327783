const arrow = document.querySelectorAll(".linked .arrow");
const linked = document.querySelectorAll(".linked");

linked.forEach((el,i) => {
      el.addEventListener("mouseenter", (e) => {
            let Hovered = e.target;
            arrow[i].style.transition = `all 150ms ease-in-out`;
            arrow[i].style.transform = `translate(4px,-4px)`;
      });
});

linked.forEach((el,i) => {
    el.addEventListener("mouseout", (e) => {
          let Hovered = e.target;
          arrow[i].style.transition = `all 150ms ease-in-out`;
          arrow[i].style.transform = `translate(0px,0px)`;
    });
});

